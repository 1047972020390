import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("catalogo/cursos-capacitaciones/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("catalogo/cursos-capacitaciones/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("catalogo/cursos-capacitaciones", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("catalogo/cursos-capacitaciones/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("catalogo/cursos-capacitaciones", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleados(parametros) {
        return Vue.axios.get("cursos/empleados", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteEmpleado(parametros) {
        return Vue.axios.post("cursos/delete", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeEmpleados(parametros) {
        return Vue.axios.post("cursos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEmpleados(parametros) {
        return Vue.axios.put("cursos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    findEmpleados(id) {
        return Vue.axios.get("cursos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}