import gql from 'graphql-tag';

const cursosCapacitacionesTableQuery = gql`
    query cursos_capacitaciones($whereCondition:CursosCapacitacionesWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        cursos_capacitaciones(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                nombre,
                fecha_inicio,
                fecha_fin,
                total_horas
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const getCursosCapacitaciones = gql`
    query cursos_capacitaciones($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        cursos_capacitaciones(first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                nombre,
                fecha_inicio,
                fecha_fin,
                total_horas
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { cursosCapacitacionesTableQuery, getCursosCapacitaciones };

export default queries;
